import React, { useEffect, useState } from 'react';

import { GenericTable } from '../../../../components/GenericTable';
import { GenericPage } from '../../../../components/GenericPage';

import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { formatDate } from '../../../../utils/getFormatDate';

import { ICompany } from '../../../../types/ICompany';

const HEADER = [
  { 
    key: 'document', 
    label: 'Documento', 
    order: 1,
    textAlign: 'left',
    callback: (v: string) => v.length === 14 ? `CPF ${v}` : `CNPJ ${v}`
  },
  { 
    key: 'fantasy_name', 
    label: 'Nome',
    order: 2,
    textAlign: 'left'
  },
  {
    key: 'createdAt', 
    label: 'Criação',
    order: 3,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt', 
    label:  'Última alteração',
    order: 4,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];

const ACTIONS = [
  { 
    type: 'link', 
    label: 'Selecionar', 
    link: '/backoffice/empresas', 
    bgColor: 'var(--dark-gray)', 
    color: 'var(--white)',
    linkCallback: (l: string, v: ICompany) => `${l}/${v._id}`
  },
  { 
    type: 'button', 
    label: 'Apagar', 
    link: '', 
    callback: (v: ICompany) => console.log('Tem certeza que deseja excluir?', v), 
    bgColor: 'var(--red)', 
    color: 'var(--white)'
  }
];

export const CompanyList: React.FC = () => {
  const { addToast } = useToast();

  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadCompanies = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<ICompany[]>('/companies');
        if (data && data.length > 0) {
          setCompanies(data)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar as empresas',
        });
      }
    };
      
    loadCompanies();
      
  }, [addToast]);

  return (
    <GenericPage
      breadcrumb="BackOffice > "
      pageTitle="Empresas"
      loading={loading}
    >
      {companies.length > 0 && (
        <GenericTable 
          headerData={HEADER}
          actionData={ACTIONS}
          bodyData={companies}
          title="Empresas"
        />
      )}
    </GenericPage>
  )
};
