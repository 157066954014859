import React, { useEffect, useState } from 'react';

import { GenericTable } from '../../../../components/GenericTable';
import { GenericPage } from '../../../../components/GenericPage';

import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { formatDate } from '../../../../utils/getFormatDate';

import { IUser } from '../../../../types/IUser';
import { ICompany } from '../../../../types/ICompany';

const MOCK_USERS = [
  {
    _id: "614b28a14d837c001d00c3dc",
    name: "Sergio Gomes",
    email: "sergio.gomes@certsys.com.br",
    roles: ["dpo", "admin", "user"],
    no_pass: false,
    createdAt: "2021-09-22T12:59:13.919Z",
    updatedAt: "2021-10-04T11:35:40.444Z",
    __v: 0,
    company_id: {
      cookie_id: "617c0db52a2629001dfb05ba",
      createdAt: "2021-09-22T13:20:22.310Z",
      document: "40.179.411/0001-00",
      fantasy_name: "SPFG TECNOLOGIA E DESENVOLVIMENTO LTDA M ",
      hasCookieBar: true,
      hasPolicy: true,
      policy_slug: "1635952923871-spfg-tecnologia-e-desenvolvimento-ltda-m-",
      updatedAt: "2021-10-04T11:35:40.015Z",
      __v: 0,
      _id: "614b2d964d837c001d00c41b",
    }
  }
];
const HEADER = [
  { 
    key: 'name', 
    label: 'Nome', 
    order: 1,
    textAlign: 'left',
  },
  { 
    key: 'email', 
    label: 'Email',
    order: 2,
    textAlign: 'left'
  },
  { 
    key: 'roles', 
    label: 'Perfis',
    order: 3,
    textAlign: 'left',
    callback: (v: string[]) => v.join('; ')
  },
  { 
    key: 'company_id', 
    label: 'Empresa',
    link: '/backoffice/empresas', 
    order: 4,
    textAlign: 'left',
    callback: (v: ICompany) => v.fantasy_name,
    linkCallback: (l: string, v) => `${l}/${v.company_id._id}`
  },
  {
    key: 'createdAt', 
    label: 'Criação',
    order: 5,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt', 
    label: 'Última alteração',
    order: 6,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];

const ACTIONS = [
  { 
    type: 'link', 
    label: 'Selecionar', 
    link: '/backoffice/usuarios', 
    bgColor: 'var(--dark-gray)', 
    color: 'var(--white)',
    linkCallback: (l: string, v: IUser) => `${l}/${v._id}`
  }
];

export const UserList: React.FC = () => {
  const { addToast } = useToast();

  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<IUser[]>('/users');
        if (data && data.length > 0) {
          setUsers(data)
        }
        setLoading(false);
      } catch (error) {
        // setUsers(MOCK_USERS)
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar as usuários',
        });
      }
    };
      
    loadUsers();
      
  }, [addToast]);

  return (
    <GenericPage
      breadcrumb="BackOffice > "
      pageTitle="Usuários"
      loading={loading}
    >
      {users.length > 0 && (
        <GenericTable 
          headerData={HEADER}
          actionData={ACTIONS}
          bodyData={users}
          title="Usuários"
        />
      )}
    </GenericPage>
  )
};
