import styled, { css, keyframes } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
  dark?: boolean;
}

const appearFromMiddle = keyframes`
  from {
    opacity: 0;
    transform: translate(2rem, .625rem);
  }
  to {
    opacity: 1;
    transform: translateX(1rem, 0);
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  border-bottom: 2px solid ${props => (props.dark ? '#33333399' : '#ffffff99')};
  padding: 1rem;
  padding-top: 2rem;
  width: 100%;
  color: ${props => (props.dark ? 'var(--dark-gray)' : 'var(--white)')};

  display: flex;
  align-items: center;

  transition: all 0.3s;

  ${props =>
    props.hasError &&
    css`
      border-color: #ff0000;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--white);
      border-color: var(--white);
    `}
  ${props =>
    props.isFocused &&
    props.dark &&
    css`
      color: var(--white);
      border-color: var(--white);
    `}

  ${props =>
    props.isFocused &&
    props.dark &&
    css`
      color: var(--dark-gray);
      border-color: var(--dark-gray);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--white);
    `}
  ${props =>
    props.isFilled &&
    props.dark &&
    css`
      color: var(--dark-gray);
    `}


  ${props =>
    (props.isFocused || props.isFilled) &&
    css`
      span {
        position: absolute;
        font-size: 0.75rem;
        top: 0;
        left: 0;
        animation: ${appearFromMiddle} 0.3s;
      }
    `}

  ${props =>
    (props.isFocused || props.isFilled) &&
    css`
    aside {
      span {
        position: absolute;
        display: table;
        font-size: 0.75rem;
        top: -40px;
        left: 10px;
        animation: ${appearFromMiddle} 0.3s;
      }
    }
    `}


    input {
    background: transparent;
    border: 0;
    flex: 1;
    color: ${props => (props.dark ? 'var(--dark-gray)' : 'var(--white)')};
    font-size: 1rem;

    &::placeholder {
      color: ${props => (props.dark ? '#33333399' : '#ffffff99')};
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

export const Error = styled(Tooltip)`
  height: 1.25rem;
  margin-left: 1rem;

  svg {
    margin: 0;
  }

  span {
    background: #ff0000;
    color: #fff;

    &::before {
      border-color: #ff0000 transparent;
    }
  }
`;
