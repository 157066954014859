import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';
import { api } from '../services/api';

import { IDSR } from '../types/IDSR';
import { ICompanyResponse } from '../types/ICompanyResponse';

interface CreateEditDSR {
  isCreation: boolean;
  openPopup: boolean;
}

interface CookieBarStyle {
  fontColor: string;
  barColor: string;
  fontFamily: string;
}

export const useCreateDSR = () => {
  const [email, setEmail] = useState("")
  const [fontColor, setFontColor] = useState('');
  const [emphasisColor, setEmphasisColor] = useState('');
  const [fontFamily, setFontFamily] = useState('');
  const [firstAccess, setFirstAccess] = useState(false);

  const { id: DSRId } = useParams<{ id: string }>();

  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const [stepsCompleted, setStepsCompleted] = useState(0);
  
  const history = useHistory();

  const [showBarPicker, setShowBarPicker] = useState(false);

  const [barColor, setBarColor] = useState('');
  
  const [url, setUrl] = useState('');

  const [fontSize, setFontSize] = useState('12px');
  const [fontWeight, setFontWeight] = useState('bold');
  const [hasPolicyBond, setHasPolicyBond] = useState(false);
  const [notCreatedPolicy, setNotCreatedPolicy] = useState(false);

  // Handles the flow of when the user already has the site url in the policy module
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModal, setShowExistingSiteUrlModal] = useState(false);
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModalOnce, setShowExistingSiteUrlModalOnce] = useState(false);

  const [showCookieTypes, setShowCookieTypes] = useState(false);
  const [generateHTML, setGenerateHTML] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasPolicyCreated, setHasPolicyCreated] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [replacedHtml, setReplacedHtml] = useState('');
  const [htmlDSR, setHtmlDSR] = useState(
    `
    <style type="text/css">
   *{
      box-sizing: content-box;
    }
    .geral {
      padding-top: 64px;
      padding-left: 92px;
      padding-right: 92px;
      padding-bottom: 64px;

    }

    #title {
        font-family: /*replace_font_family*/;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        line-height: 25.1px;
        color: /*replace_font_color*/;
        margin-bottom: 40px;
        margin-top:0;
    }

    .itens-title {
        font-family: /*replace_font_family*/;
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
        line-height: 22.59px;

        color: /*replace_font_color*/;

        padding: 0px;
        margin: 0px;
    }

    .itens-content {
        font-family: /*replace_font_family*/;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
        line-height: 22.59px;

        color: /*replace_font_color*/;

        padding: 0px;
        margin: 0px;
        ;
    }

    .group-itens {
        display: flex;
        margin-bottom: 24px;
        ;
    }

    .radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 10px;
        left:0px;
        height: 24px;
        width: 24px;
        border: 2px solid #D0D0D0;
        border-radius: 50%;
    }

    .radio-container input:checked~.checkmark {
        height: 12px;
        width: 12px;
        border: 8px solid /*replace_emphasis_color*/;
    }
    .button-content{
        display: flex;
        justify-content: center;
    }
    .privaciflow_button_save_dsr {
        width: 127px;
        height: 48px;
        border-radius: 8px;
        padding: 14px 32px 14px 32px;
        background-color: /*replace_emphasis_color*/;
        border:none;
        box-sizing: border-box;
        color: #FFFFFF;
        font-size: 16px;
        font-family: /*replace_font_family*/;
        font-weight: 400;   
    }

    .privaciflow_button_save_dsr:hover{
        filter: brightness(0.7);
    }
    .privaciflow_button_save_dsr:disabled{
        filter: brightness(0.7);
        cursor:not-allowed;
    }
    @media screen and (max-width: 425px) {
        .geral{
            padding: 10px;
            margin-bottom: 2rem;
        }
        .button-content {
            display: flex;
            justify-content: left;
        }
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 16px;
        }
    }

    @media screen and (min-width: 425px) {
        .geral{
            padding-top: 32px;
            padding-left: 45px;
            padding-right: 45px;
            padding-bottom: 32px;
        }
        
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 16px;
        }
    }

    @media screen and (min-width: 768px) {
        .geral{
            margin: 0 auto;
            width: 631px;
        }
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 16px;
        }
    }
    @media screen and (min-width: 992px) {
        .geral {
            margin: 0 auto;
            width: 631px;
        }

        #title {
            font-size: 20px;
        }

        .itens-title {
            font-size: 18px;
        }

        .itens-content {
            font-size: 18px;
        }
    }
    </style>
    <!-- HTML ============================= -->
    <div class="geral">
    <p id="title">
        Como titular de seus dados pessoais, você pode exercer seus direitos e solicitar as opções abaixo.
    </p>
    <div class="group-itens" >
        <label class="radio-container">
            <input type="radio" name="radio" onclick="typeSelectedDSR('rectify')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Retificar</p>
                <p class="itens-content">Gostaria de portar meus dados para outra empresa de serviço ou produto</p>
            </div>
        </label> 
    </div>
    <div class="group-itens">
        <label class="radio-container">
            <input type="radio" name="radio" onclick="typeSelectedDSR('portability')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Portabilidade </p>
                <p class="itens-content">Gostaria de portar meus dados para outra empresa de serviço ou produto</p>
            </div>
        </label>
    </div>
    <div class="group-itens">
        <label class="radio-container">
            <input type="radio" name="radio" onclick="typeSelectedDSR('confirmation')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Confirmação</p>
                <p class="itens-content">Quero confirmar/consultar quais dados vocês tem a meu respeito</p>
            </div>
        </label>
    </div>
    <div class="group-itens">
        <label class="radio-container">
            <input type="radio" name="radio" onclick="typeSelectedDSR('access')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Acesso</p>
                <p class="itens-content">Quero uma cópia de todos os dados que vocês tem a meu respeito</p>
            </div>
        </label>
    </div>
    <div class="group-itens">
        <label class="radio-container">
            <input type="radio" name="radio"  onclick="typeSelectedDSR('elimination')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Eliminação</p>
                <p class="itens-content">Me arrependi de dar o meu consentimento e quero apagar todos os meus dados</p>
            </div>
        </label>
    </div>
    <div class="group-itens">
        <label class="radio-container">
            <input type="radio" name="radio"  onclick="typeSelectedDSR('dataSharing')">
            <span class="checkmark"></span>
            <div>
                <p class="itens-title">Compartilhamento de dados</p>
                <p class="itens-content">Quero consultar se vocês compartilham os meus dados, com quem e porquê.</p>
            </div>
        </label>
    </div>
    <div class="button-content"><button class="privaciflow_button_save_dsr" id="button_dsr" disabled onclick="trigger()">Solicitar</button></div>
</div>
    <!-- Scripts ============================= -->
    <script>
    let type = ''
    let DSRId = "/*replace_dsr_id*/";
    let apiUrlPrivaciflow = "/*replace_api_endpoint*/";
    let email = "/*replace_email*/";

    async function trigger(){
      saveInteractionDSR(this.type)
      window.open(optionDSR(this.type));
    }

    function typeSelectedDSR(type){
        this.type = type
        document.getElementById('button_dsr').disabled = false
    }
    function optionDSR(type){
        switch (type) {
            case 'rectify':
                return 'mailto:'+email+'?subject=Preciso%20corrigir%20ou%20atualizar%20meus%20dados&body=Preencha%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20nas%20nossas%20bases%3A%0ANome%20completo%3A%0AData%20de%20nascimento%3A%0ACPF%3A%0AInforme%20e%20descreva%20os%20dados%20voc%C3%AA%20deseja%20atualizar%2C%20por%20exemplo%2C%20se%20for%20o%20seu%20telefone%2C%20digite%20o%20n%C3%BAmero%20do%20seu%20telefone%20atual%2C%20se%20for%20o%20seu%20nome%2C%20escreva%20o%20seu%20nome%20atual%2C%20etc.'
            case 'portability':
                return 'mailto:'+email+'?subject=Gostaria%20de%20portar%20meus%20dados%20para%20outra%20empresa%20de%20servi%C3%A7o%20ou%20produto&body=Preencha%20%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20ou%20produto%20bases%3A%0ANome%20Completo%3A%0AData%20de%20Nascimento%3A%0ACPF%3A'
            case 'confirmation':
                return 'mailto:'+email+'?subject=Gostaria%20de%20portar%20meus%20dados%20para%20outra%20empresa%20de%20servi%C3%A7o%20ou%20produto&body=Preencha%20%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20ou%20produto%20bases%3A%0ANome%20Completo%3A%0AData%20de%20Nascimento%3A%0ACPF%3A'
            case 'access':
                return 'mailto:'+email+'?subject=Gostaria%20de%20portar%20meus%20dados%20para%20outra%20empresa%20de%20servi%C3%A7o%20ou%20produto&body=Preencha%20%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20ou%20produto%20bases%3A%0ANome%20Completo%3A%0AData%20de%20Nascimento%3A%0ACPF%3A'
            case 'elimination':
                return 'mailto:'+email+'?subject=Me%20arrependi%20de%20dar%20o%20meu%20consentimento%20e%20quero%20apagar%20todos%20os%20meus%20dados&body=Preencha%20%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20nas%20nossas%20bases%3A%0ANome%20Completo%3A%0AData%20de%20Nascimento%3A%0ACPF%3A%0AInforme%20os%20dados%20voc%C3%AA%20deseja%20eliminar%2C%20por%20exemplo%2C%20seu%20n%C3%BAmero%20de%20telefone%2C%20seu%20endere%C3%A7o%2C%20seu%20e-mail...%20ou%20todos%20os%20dados%20a%20seu%20respeito.'
            case 'dataSharing':
                return 'mailto:'+email+'?subject=Gostaria%20de%20portar%20meus%20dados%20para%20outra%20empresa%20de%20servi%C3%A7o%20ou%20produto&body=Preencha%20%20os%20dados%20abaixo%20para%20identificarmos%20voc%C3%AA%20ou%20produto%20bases%3A%0ANome%20Completo%3A%0AData%20de%20Nascimento%3A%0ACPF%3A'
            default:
                return 'mailto:'+email+'?subject=DSR'
                break;
        }
    }

    function saveInteractionDSR(type) {
        try {
            var interaction = {
                dsr_id: DSRId,
                type,
            };
            var interactionStr = JSON.stringify(interaction);
            httpAsync(apiUrlPrivaciflow + "dsr/interactions", "POST", handleInteractionsResponse, interactionStr);
        } catch (error) {
            console.error(error);
        }
    }

    function httpAsync(theUrl, method, callback, data) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                callback(xmlHttp.responseText);
            }
        }
        xmlHttp.open(method, theUrl, true);
        if (data) {
            xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xmlHttp.send(data);
        } else {
            xmlHttp.send(null);
        }
    }
    function handleInteractionsResponse(data) {
    }
    </script>
    `,
  );
  const [htmlCopied, setHTMLCopied] = useState<boolean>(false);

  const [databaseCookieBar, setDatabaseCookieBar] = useState<CookieBarStyle>(
    {} as CookieBarStyle,
  );

  const loadCookieBarForCopy = async (_cookieBarId: string) => {
    try {
      setLoading(true);
      const response = await api.get<IDSR>(
        `/cookie_bars/${_cookieBarId}`,
      );
      const h = replaceHTMLDSR(
        htmlDSR,
        response.data._id,
        response.data.font_family,
        response.data.cookie_bar_color,
        response.data.emphasis_color,
        response.data.email,
      )
      navigator.clipboard.writeText(h);
      setHTMLCopied(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const loadCompanyData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get<ICompanyResponse>(
        `/companies/${user.company_id?._id}`,
      );

      if (data && data.policies && data.policies.length > 0) {
        setUrl(data.policies[0].site_url);
        if (!showExistingSiteUrlModalOnce) {
          setShowExistingSiteUrlModal(true);
          setShowExistingSiteUrlModalOnce(true);
        }
        if (data.policies[0].cookie_bar_bond) {
          setHasPolicyBond(true)
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha ao carregar',
        description:
          'Ocorreu um erro ao carregar os dados da sua empresa',
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    api.get(`companies/${user?.company_id._id}`)
      .then((response) => {
        const {
          policies
        } = response.data;
        setHasPolicyCreated(policies.length > 0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
  }, [])

  const loadCookieBar = async (_cookieBarId: string) => {
    try {
      setLoading(true);
      const response = await api.get<IDSR>(
        `/dsr/${_cookieBarId}`,
      );

      const {
        site_url,
        font_color,
        emphasis_color,
        font_family,
      } = response.data;

      setUrl(site_url);
      setFontColor(font_color);
      setEmail(response.data.email);
      setEmphasisColor(emphasis_color);
      setFontFamily(font_family);

      if(response.data.email){
        setFirstAccess(false)
      }else{
        setFirstAccess(true)
      }
     
      setDatabaseCookieBar({
        fontColor: font_color,
        barColor: emphasis_color,
        fontFamily: font_family,
      });

      setTimeout(() => {
        setRequestSave(false);
      }, 200);
      setLoading(false);
    } catch (error) {
      loadCompanyData();
      setLoading(false);
      setTimeout(() => {
        setRequestSave(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (DSRId) {
      try {
        loadCookieBar(DSRId);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os dados da barra de cookies',
        });
      }
    }
  }, [addToast, DSRId]);

  useEffect(() => {
    let stepCount = 0;

    if (url) stepCount += 1;
    if (fontColor) stepCount += 1;

    setStepsCompleted(stepCount);
    setRequestSave(true);
  }, [
    url,
    fontColor,
    fontFamily,
    emphasisColor,
    email,
    setStepsCompleted,
    databaseCookieBar,
    addToast,
    setRequestSave,
  ]);

  const calcLineHeight = size => {
    if (!size) return '20px';
    const num = parseInt(size.replace('px', ''), 10) + 4;
    return `${num}px`;
  };

  const replaceHTMLDSR = (
    _html: string,
    _dsrId: string,
    _fontFamily: string,
    _fontColor: string,
    _emphasisColor: string,
    _email: string,
  ) => {
    let h = _html;

    // Styling Script dinamically
    h = h.replace(/\/\*replace_dsr_id\*\//g, _dsrId);
    h = h.replace(/\/\*replace_api_endpoint\*\//g, `${process.env.REACT_APP_PRIVACY_API || 'http://localhost:3333/api/v1'}/`);
    h = h.replace(/\/\*replace_email\*\//g, `${_email}`);
    // Styling CSS dinamically
    h = h.replace(/\/\*replace_font_family\*\//g, _fontFamily);
    h = h.replace(/\/\*replace_font_color\*\//g, _fontColor);
    h = h.replace(/\/\*replace_emphasis_color\*\//g, _emphasisColor);

    return h;
  };

  const handleCopyHTML = () => {
    const h = replaceHTMLDSR(
      htmlDSR,
      DSRId,
      fontFamily,
      fontColor,
      emphasisColor,
      email,
    );
    navigator.clipboard.writeText(h);
    setHTMLCopied(true);
  };

  const closeModal = async (hasCookieBar: boolean, hasPolicy: boolean) => {
    setGenerateHTML(false);
    setHTMLCopied(false);

    if (hasPolicy) {
      history.push('/atividades/corporativo');
    } else if (hasPolicyBond && !hasPolicyCreated) {
      setNotCreatedPolicy(true);
    } else {
      history.push('/atividades/corporativo');
    }
  };

  const handleSaveCookieTypes = () => {
    setShowCookieTypes(prev => !prev);
  };

  // eslint-disable-next-line consistent-return
  const handleCreateEditDSR = async ({
    isCreation,
    openPopup,
  }: CreateEditDSR) => {
    
    try {
      const reqBody = {
        company_id: user.company_id._id,
        site_url: url,
        font_family: fontFamily || 'Arial',
        font_color: fontColor || '#707070',
        emphasis_color: emphasisColor || '#000000'
      };

      if (!reqBody.site_url.includes('http://') && !reqBody.site_url.includes('https://')) {
        reqBody.site_url = `https://${reqBody.site_url}`;
      }

      setLoading(true);
      let response: any;
      let dsr_id: string;

      if (isCreation) {
        response = await api.post('dsr', reqBody);
        // Disabling request to save before the history replace
        setRequestSave(false);
        history.replace(`${response.data._id}`);
        dsr_id = response.data._id;

        setShowExistingSiteUrlModal(false);
      } else {
        const html_replace = replaceHTMLDSR(
          htmlDSR,
          DSRId,
          fontFamily,
          fontColor,
          emphasisColor,
          email
        );

        dsr_id = DSRId;
        response = await api.put(`dsr/${DSRId}`, {
          ...reqBody,
          email,
          dsr_html: html_replace
        });

        if (openPopup) {
    
          if (hasPolicyBond && !hasPolicyCreated) {
            setNotCreatedPolicy(true);
          } else {
            setGenerateHTML(prev => !prev);
          }
          setReplacedHtml(
            replaceHTMLDSR(
              htmlDSR,
              DSRId,
              fontFamily,
              fontColor,
              emphasisColor,
              email,
            ),
          );
        }
      }

      setDatabaseCookieBar({
        fontColor,
        barColor,
        fontFamily,
      });
      setTimeout(() => {
        // Disabling request to save after setting the cookie bar data
        setRequestSave(false);
        setLoading(false);
      }, 200);

      updateUser({
        ...user,
        company_id: {
          ...user.company_id,
          hasDSR: true,
          dsr_id,
        },
      });

      let message = '';
      if (isCreation && !openPopup) {
        message = 'URL inserida com sucesso!';
      }
      if (!isCreation && !openPopup) {
        message = `
          DSR salvo com sucesso!
          Para copiar o HTML do DSR basta ir para o menu editar.
        `;
      }
      if (!isCreation && openPopup) {
        message = 'DSR salvo com sucesso!';
      }

      addToast({
        type: 'success',
        title: 'Salvo',
        description: message,
      });
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao salvar DSR',
      });
    }
  };

  const handleClickBegin = () => {
    handleCreateEditDSR({
      isCreation: true,
      openPopup: false,
    });
  };

  return {
    stepsCompleted,
    showBarPicker,
    url,
    htmlDSR,
    replacedHtml,
    replaceHTMLDSR,
    barColor,
    fontColor,
    fontFamily,
    fontWeight,
    fontSize,
    generateHTML,
    loading,
    htmlCopied,
    showCookieTypes,
    setShowBarPicker,
    setUrl,
    setHtmlDSR,
    setBarColor,
    setFontColor,
    setFontFamily,
    setFontWeight,
    setFontSize,
    requestSave,
    setRequestSave,
    setGenerateHTML,
    setShowCookieTypes,
    handleCopyHTML,
    closeModal,
    handleSaveCookieTypes,
    handleCreateEditDSR,
    loadCookieBarForCopy,
    hasPolicyBond,
    setHasPolicyBond,
    notCreatedPolicy,
    setNotCreatedPolicy,
    showExistingSiteUrlModal,
    handleClickBegin,

    emphasisColor,
    setEmphasisColor,
    email,
    setEmail,
    firstAccess
  };
};
