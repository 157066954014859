import axios from 'axios';

const accessToken = sessionStorage.getItem('@Privacyflow:access_token');

export const api = axios.create({
  baseURL: process.env.REACT_APP_PRIVACY_API || 'http://localhost:3333/api/v1',
  // baseURL: "https://privacyflow.development.tec.br/api/v1/",
  timeout: 100000,
  headers: {
    'Authorization': accessToken ? `Bearer ${accessToken}` : ''
  },
});
