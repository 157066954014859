import React, { useRef, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import _ from 'lodash';

import { useAuth, SignInCredentials } from '../../contexts/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { parseQueryStr } from '../../utils/parseQueryString';

export const SignIn: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = useCallback(
    async (data: SignInCredentials) => {
      try {
        const search = _.get(history, 'location.search', false);
        const clonedData = _.clone(data);
        clonedData.email = clonedData.email.trim();

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail, válido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(clonedData, { abortEarly: false });
        setLoading(true);
        const userSuccess = await signIn(clonedData);
        setLoading(false);

        if (userSuccess) {
          const noPass = _.get(userSuccess, 'no_pass', false);
          if (noPass) {
            return;
          }

          // Redirect logic
          if (search) {
            const searchParams = parseQueryStr(search);
            const redirectFound = searchParams.find(p => p.key === 'redirect');
            const params = searchParams.reduce((acc, curr) => {
              if (curr.key !== 'redirect') {
                if (acc) {
                  // eslint-disable-next-line no-param-reassign
                  acc += `&${curr.key}=${curr.value}`;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  acc += `?${curr.key}=${curr.value}`;
                }
              }
              return acc;
            }, '');
            if (redirectFound) {
              if (redirectFound.value.includes('checkout')) {
                setTimeout(() => {
                  window.location.href = redirectFound.value + params;
                }, 200);
                return;
              }
              history.push(redirectFound.value + params);
              return;
            }
          }

          history.push('/');
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [history, signIn],
  );

  return (
    <Container>
      <MaturityHeader />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Acesse a sua conta</h2>

          <section>
            <Input name="email" placeholder="E-mail" />
            <Input name="password" type="password" placeholder="Senha" />
          </section>

          <Button loading={loading} light>
            Concluir
          </Button>
          <Link to="/forgot_pass">Esqueci minha senha</Link>
        </Form>
      </Content>
    </Container>
  );
};
