import React, { useEffect, useState } from 'react';
import { GenericPage } from '../../../../components/GenericPage';
import { GenericTable } from '../../../../components/GenericTable';
import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';

import { IPlan } from '../../../../types/IPlan';


const HEADER = [
  { 
    key: 'name', 
    label: 'Nome', 
    order: 1,
    width: '10rem', 
    textAlign: 'center'
  },
  { 
    key: 'amount', 
    label: 'Valor',
    order: 2,
    width: '8rem',
    textAlign: 'center',
    callback: (v:Number) => v === 0 ? '-' : `${numberAmount(v)}`,
  },
  { 
    key: 'admin_accounts', 
    label: 'Contas Admin',
    order: 3,
    textAlign: 'center',
    callback: (v) => `${v}`
  },
  { 
    key: 'normal_accounts',
    label: 'Contas Normais',
    order: 4,
    textAlign: 'center',
    callback: (v:Number ) => v === 0 ? '-' : `${v.toFixed(0)}`,
  },
  { 
    key: 'cookie_bars_interactions', 
    label: 'Interações',
    order: 5,
    textAlign: 'center',
    callback: (v:Number) => v === -1 ? 'Ilimitado' : `${v} (max)`,
  },
  { 
    key: 'interactions_reports',
    label: 'Exportar interações',
    order: 6,
    textAlign: 'center',
    callback: (v: boolean) => v ? 'Permitido' : 'Não é permitido', 
  },
  { 
    key: 'dsr_forms',
    label: 'DSR',
    order: 7,
    textAlign: 'center',
    callback: (v: Number) => v === 1 ? 'Disponível' : 'Não disponível'
  }
];

const ACTIONS = [
  { 
    label: 'Selecionar', 
    order: 1,
    type: 'link', 
    link: '/backoffice/planos', 
    size: '0.5rem',
    bgColor: '#0c6cf1', 
    color: '#ffffff',
    linkCallback: (l: string, v: IPlan) => `${l}/${v._id}`
  },
  { 
    label: 'Apagar', 
    order: 1,
    type: 'button', 
    link: '', 
    callback: (v: IPlan) => console.log('Tem certeza que deseja excluir?', v), 
    bgColor: '#c31414', 
    color: '#ffffff'
  }
];
export const numberAmount = (n) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency',currency: 'BRL',}).format(n / 100)
}

export const PlanList: React.FC = () => {
  const { addToast } = useToast();

  const [plans, setPlans] = useState<IPlan[]>([]);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => { 
    const loadPlans = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<IPlan[]>('/plans');
        if (data && data.length > 0) {
          setPlans(data)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao Carregar',
          description: 'Ocorreu um erro ao carregar os Planos',
        });
      }
    };
      
    loadPlans();
      
  }, [addToast]);

  return (
    <GenericPage
      breadcrumb="BackOffice > "
      pageTitle="Planos"
      loading={loading}
    >
      <h1>Hello world!</h1>
      {plans.length > 0 && (
        <GenericTable 
          headerData={HEADER}
          actionData={ACTIONS}
          bodyData={plans}
        />
      )}
      <h1>Olá</h1>
    </GenericPage>
  )
};