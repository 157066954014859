import styled, { css } from 'styled-components';

interface CellHeaderProps {
  width?: string;
  textAlign?: string;
}

interface CellBodyProps {
  textAlign?: string;
}

interface ActionButtonProps {
  bgColor?: string;
  color?: string;
}

export const Table = styled.table`
  border-collapse: collapse;
  text-align: center;
  vertical-align: middle;
  width: 100%;
`;

export const Caption = styled.caption`
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
  color: var(--dark-gray);
  margin-bottom: 1rem;
`;

export const TableHeader = styled.thead`
  background-color: var(--dark-gray);
  color: var(--white);
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 2%;
`;

export const RowHeader = styled.tr`
`;

export const CellHeader = styled.th<CellHeaderProps>`
  padding: 1rem;
  background-color: var(--teal);
  color: var(--white);
  text-align: left;

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.textAlign &&
    css`
    text-align: ${props.textAlign ? props.textAlign : 'left'};
    `};
`;

export const TableBody = styled.tbody`
`;

export const RowBody = styled.tr`
  :nth-child(odd) {
    background-color: var(--white);
  }
  :nth-child(even) {
    background-color: var(--light-gray);
  }
`;

export const CellBody = styled.td<CellBodyProps>`
  padding: 1rem;

  ${props =>
    props.textAlign &&
    css`
    text-align: ${props.textAlign ? props.textAlign : 'left'};
    `};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ActionButton = styled.button<ActionButtonProps>`
  padding: 0.2rem 0.6rem;
  border-radius: 0.2rem;

  ${props =>
    props.bgColor &&
    css`
    background-color: ${props.bgColor ? props.bgColor : 'var(--light-gray)'};
  `};

  ${props =>
    props.color &&
    css`
    color: ${props.color ? props.color : 'var(--dark-gray)'};
  `};

  a {
    color: inherit;
    text-decoration: none;
  }
`;