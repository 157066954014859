import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { SignIn } from '../pages/SignIn';
import { ChangePassword } from '../pages/ChangePassword';

import { MaturityLanding } from '../pages/MaturityLanding';
import { MaturityResearch } from '../pages/MaturityResearch';
import { MaturityResult } from '../pages/MaturityResult';

import { Activities } from '../pages/Activities';
import { CreateCookieBar } from '../pages/Activities/Corporate/Cookies/CreateCookieBar';
import { ManageCookieBar } from '../pages/Activities/Corporate/Cookies/ManageCookieBar';
import { MaturityLandingForm } from '../pages/MaturityLanding/Form';
import { CompanyRegistration } from '../pages/CompanyRegistration';
import { AddCompanyUser } from '../pages/AddCompanyUser';
import { ForgotPass } from '../pages/ForgotPass';
import { ResetPassword } from '../pages/ResetPassword';
import { CookiesPanel } from '../pages/Activities/Corporate/Cookies/CookiesPanel';
import { ManagePolicy } from '../pages/Activities/Corporate/Policies/ManagePolicy';
import { CreatePolicy } from '../pages/Activities/Corporate/Policies/CreatePolicy';
import { CheckoutConfirm } from '../pages/checkout/confirm'
import { SlugPage } from '../pages/Slug';
import { CodFontPage } from '../pages/CodFont';
import { CreateDSR } from '../pages/Activities/Corporate/DSR/DSRForm';
import { PanelDSR } from '../pages/Activities/Corporate/DSR/DSRPanel';

// Backoffice
import { PlanList } from '../pages/BackOffice/Plans/PlanList';
import { ManagePlan } from '../pages/BackOffice/Plans/ManagePlan';
import { CompanyList } from '../pages/BackOffice/Companies/CompanyList';
import { ManageCompany } from '../pages/BackOffice/Companies/ManageCompany';
import { UserList } from '../pages/BackOffice/Users/UserList';
import { ManageUser } from '../pages/BackOffice/Users/ManageUser';
import { CookieBarList } from '../pages/BackOffice/CookieBars/CookieBarList';
import { CookieBar } from '../pages/BackOffice/CookieBars/CookieBar';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Access/Account */}
      <Route path="/" exact component={SignIn} />

      <Route path="/trocar-senha" component={ChangePassword} isPrivate />
      <Route path="/forgot_pass" component={ForgotPass} />
      <Route path="/reset_pass" component={ResetPassword} />

      {/* Maturity Research  */}
      <Route path="/maturidade" component={MaturityLanding} exact />
      <Route path="/maturidade/:type" component={MaturityLandingForm} />

      <Route path="/pesquisa-maturidade/:jwt" component={MaturityResearch} />
      <Route
        path="/resultado-maturidade/:researchId"
        component={MaturityResult}
      />

      {/* Application Core */}
      <Route
        exact
        path="/cadastrar-empresa"
        component={CompanyRegistration}
        isPrivate
      />
      <Route
        exact
        path="/editar-empresa"
        component={CompanyRegistration}
        isPrivate
      />
      <Route
        exact
        path="/cadastrar-novo-usuario"
        component={AddCompanyUser}
        isPrivate
      />

      {/* Activities */}
      <Route
        exact
        path="/atividades/corporativo"
        component={Activities}
        isPrivate
      />

      {/* Cookies */}
      <Route
        path="/atividades/corporativo/barra-de-cookies"
        component={ManageCookieBar}
        exact
        isPrivate
      />
      <Route
        path="/atividades/corporativo/barra-de-cookies/:id"
        component={CreateCookieBar}
        isPrivate
      />
      <Route
        path="/atividades/corporativo/barra-de-cookies/nova"
        component={CreateCookieBar}
        isPrivate
      />
      <Route
        path="/atividades/corporativo/painel-de-cookies"
        component={CookiesPanel}
        exact
        isPrivate
      />

      {/* Policies */}
      <Route
        path="/atividades/corporativo/politicas"
        component={ManagePolicy}
        exact
        isPrivate
      />
      <Route
        path="/atividades/corporativo/politicas/:id"
        component={CreatePolicy}
        isPrivate
      />
      <Route
        path="/atividades/corporativo/politicas/nova"
        component={CreatePolicy}
        isPrivate
      />
      <Route
        path="/slug/:slug"
        component={SlugPage}
      />
      <Route
        path="/atividades/corporativo/codigo-fonte"
        component={CodFontPage}
        isPrivate
      />
      <Route
        path="/checkout/confirm/:plan"
        component={CheckoutConfirm}
        isPrivate
      />

      {/* DSR */}
      <Route
        path="/atividades/corporativo/dsr/novo"
        exact
        component={CreateDSR}
        isPrivate
      />
      <Route
        path="/atividades/corporativo/dsr/:id"
        component={CreateDSR}
        isPrivate
      />
      <Route
        path="/atividades/corporativo/dsr-painel"
        exact
        component={PanelDSR}
        isPrivate
      />

      {/* BackOffice */}
      <Route
        path="/backoffice/planos"
        component={PlanList}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/planos/:id"
        component={ManagePlan}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/empresas"
        component={CompanyList}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/empresas/:id"
        component={ManageCompany}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/usuarios"
        component={UserList}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/usuarios/:id"
        component={ManageUser}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/barras-de-cookies"
        component={CookieBarList}
        exact
        isPrivate
      />
      <Route
        path="/backoffice/barras-de-cookies/:id"
        component={CookieBar}
        exact
      />

    </Switch>
  );
};

export default Routes;
